import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Layout from "../components/layout"

const Copy = styled.main`
  padding: 8rem 0;
  background: var(--grey);
  h1 {
    text-align: center;
    font-size: 9.6rem;
    font-weight: 400;
    padding: 4.8rem 0;
    font-family: "tahu";
    color: #e8b795;
    @media (max-width: 767px) {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }
  }
  h2 {
    font-size: 2.4rem;
    text-transform: uppercase;
    padding-bottom: 2.4rem;
  }
  h3 {
    font-size: 2rem;
    text-transform: uppercase;
    padding-bottom: 2rem;
  }
  p,
  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ul {
    list-style-type: square;
    list-style-position: inside;
    padding-bottom: 1.6rem;
  }
  li {
    padding-bottom: 1rem;
  }
`

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo
        title="Política de Privacidade"
        description="Nesta página podes saber tudo sobre a Política de Privacidade da Boomsy"
      />
      <Copy>
        <div className="container">
          <h1>Política de Privacidade</h1>
          <h2>SECÇÃO 1 - O QUE FAZEMOS COM AS SUAS INFORMAÇÕES?</h2>
          <p>
            Quando compra alguma coisa na nossa loja, como parte do processo de
            compra e venda, colectamos as informações pessoais que nos fornece,
            tais como o seu nome, endereço e e-mail.
          </p>
          <p>
            Quando navega na nossa loja, recebemos também automaticamente o
            protocolo de internet do seu computador (endereço de IP) a fim de
            obter informações que nos ajudam a saber mais sobre o seu navegador
            e sistema operacional.
          </p>
          <p>
            Marketing por e-mail (quando aplicável): Com a sua permissão,
            podemos enviar-lhe e-mails sobre a nossa loja, novos produtos e
            outras atualizações.
          </p>
          <h2>SECÇÃO 2 - CONSENTIMENTO</h2>

          <h3>Como obtêm o meu consentimento?</h3>

          <p>
            Quando nos fornece as suas informações pessoais para completar uma
            transação, verificar o seu cartão de crédito, fazer um pedido,
            requerer uma entrega ou retornar uma compra, está a concordar com a
            nossa coleta e uso de informações pessoais apenas para esses fins
            específicos.
          </p>

          <p>
            Se pedirmos as suas informações pessoais por uma razão secundária,
            como marketing, vamos pedir-lhe o seu consentimento e dar-lhe a
            oportunidade de o negar.
          </p>

          <h3>Como posso retirar o meu consentimento?</h3>

          <p>
            Caso depois de nos fornecer os seus dados mude de ideias, pode
            retirar o seu consentimento quando quiser e assim evitar que
            entremos em contato para obter ou divulgar informações. Entre em
            contato connosco através do email:{" "}
            <a href="mailto:info@boomsy.pt">info@boomsy.pt</a>
          </p>

          <h2>SECÇÃO 3 - DIVULGAÇÃO</h2>
          <p>
            Podemos divulgar suas informações pessoais se formos obrigados por
            lei a fazê-lo ou se violar nossos Termos de Serviço.
          </p>

          <h2>SECÇÃO 4 - SHOPIFY</h2>
          <p>
            Nossa loja é hospedada no Shopify Inc. Eles fornecem-nos uma
            plataforma de e-commerce online que nos permite vender os nossos
            produtos e serviços.
          </p>
          <p>
            Os seus dados estão armazenados através do armazenamento de dados do
            Shopify, de bancos de dados e do aplicativo geral do Shopify. Eles
            armazenam dados num servidor seguro e protegido por firewall.
          </p>
          <h3>Pagamento</h3>
          <p>
            Se escolher um gateway de pagamento direto para completar a sua
            compra, o Shopify armazena os seus dados de cartão de crédito. Eles
            são criptografados através do Padrão de segurança de dados do setor
            de pagamento com cartão (PCI-DSS). Os seus dados de transação de
            compra são armazenados apenas pelo período necessário para completar
            a sua transação de compra. Depois de finalizar a compra, as suas
            informações de transação são apagadas.
          </p>
          <p>
            Todos os gateways de pagamento direto aderem aos padrões definidos
            pela PCI-DSS, que são gerenciados pelo Conselho de normas de
            segurança PCI. Ele é um esforço conjunto de marcas como Visa,
            MasterCard, American Express e Discover.
          </p>
          <p>
            Os requisitos da PCI-DSS ajudam a garantir a utilização segura de
            informações de cartão de crédito pela nossa loja e dos provedores de
            serviço.
          </p>
          <p>
            Para mais detalhes, leia os Termos de serviço ou a Política de
            privacidade do Shopify.
          </p>
          <h2>SECÇÃO 5 - SERVIÇOS DE TERCEIROS</h2>
          <p>
            No geral, os nossos fornecedores terceirizados irão coletar, usar e
            divulgar as suas informações apenas na medida do necessário para
            permitir que realizem os serviços que nos fornecem.
          </p>
          <p>
            Entretanto, certos prestadores de serviços, tais como gateways de
            pagamento e outros processadores de transações de pagamento, têm as
            suas próprias políticas de privacidade em relação à informação que
            somos obrigados a fornecer sobre transações relacionadas a compras.
          </p>
          <p>
            Para esses fornecedores, recomendamos que leia as suas políticas de
            privacidade, para que possa entender de que maneira as suas
            informações pessoais serão utilizadas.
          </p>
          <p>
            Lembre-se que certos fornecedores podem estar localizados ou possuir
            instalações que ficam em jurisdições diferentes da sua ou da nossa.
            Por isso, se quiser continuar com uma transação que envolva um
            prestador de serviços, as suas informações podem ficar sujeitas à
            legislação da(s) jurisdição(ões) onde o prestador de serviços ou
            suas instalações estiverem localizados.
          </p>
          <p>
            Por exemplo, se você está em Portugal e sua transação é processada
            por um gateway de pagamento nos Estados Unidos, as suas informações
            pessoais usadas para completar a transação podem estar sujeitas à
            divulgação sob a legislação dos Estados Unidos, incluindo a Lei
            Patriótica.
          </p>
          <p>
            Uma vez que sair do site da nossa loja ou for redirecionado para um
            aplicativo ou site de terceiros, não será mais regido por esta
            Política de Privacidade ou pelos Termos de serviço do nosso site.
          </p>

          <h3>Links</h3>

          <p>
            Quando clicar em links na nossa loja, pode ser redirecionado para
            fora do nosso site. Não somos responsáveis pelas práticas de
            privacidade de outros sites e incentivamos a leitura das respectivas
            políticas de privacidade.
          </p>

          <h2>SECÇÃO 6 - SEGURANÇA</h2>
          <p>
            Para proteger as suas informações pessoais, tomamos precauções e
            seguimos as melhores práticas da indústria para nos certificar que
            elas não são perdidas, usurpadas, acessadas, divulgadas, alteradas
            ou destruídas.
          </p>
          <p>
            Se nos fornecer as suas informações de cartão de crédito, elas serão
            criptografadas usando a tecnologia "secure socket layer" (SSL) e
            armazenadas com criptografia AES-256. Embora nenhum método de
            transmissão pela Internet ou armazenamento electrónico seja 100%
            seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos
            medidas adicionais aceitas pelos padrões da indústria.
          </p>

          <h2>COOKIES</h2>
          <p>
            Segue uma lista dos cookies que usamos. Estão listados para que
            possa optar ou não pelos cookies.
          </p>

          <p>
            <em>_session_id</em> - token único, por sessão: Permite ao Shopify
            armazenar informações sobre a sua sessão (referenciador, landing
            page, etc.)
          </p>
          <p>
            <em>_shopify_visit</em> - nenhum dado retido: Persistente por 30
            minutos da última visita, usado pelo rastreador de estatísticas
            interno do provedor do site para gravar o número de visitas
          </p>
          <p>
            <em>_shopify_uniq</em> - nenhum dado retido: Expira à meia-noite
            (relativo ao visitante) do próximo dia, conta o número de visitas de
            uma loja de um único cliente.
          </p>
          <p>
            <em>carrinho</em> - token único: Persistente por 2 semanas, armazena
            informações sobre os itens do seu carrinho.
          </p>
          <p>
            <em>_secure_session_id</em> - token único: Por sessão.
          </p>
          <p>
            <em>storefront_digest</em> - token único: Indefinido, se a loja tem
            uma senha, ela é usada para determinar se o visitante atual tem
            acesso.
          </p>
          <h2>SECÇÃO 7 - IDADE DE CONSENTIMENTO</h2>
          <p>
            Ao usar este site, confirma que é maior de idade no seu estado ou
            província de residência e que nos deu o seu consentimento para
            permitir que qualquer um dos seus dependentes menores de idade usem
            esse site.
          </p>
          <h2>SECÇÃO 8 - ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h2>
          <p>
            Reservamo-nos no direito de modificar a política de privacidade a
            qualquer momento. Portanto, por favor, leia-a com frequência. As
            alterações e esclarecimentos surtem efeito imediatamente após serem
            publicadas no site. Caso façamos alterações na política de
            privacidade, iremos notificá-lo sobre a atualização. Assim, você
            saberá quais informações coletamos, como as usamos, e sob que
            circunstâncias, caso aplicável, as utilizaremos e/ou divulgaremos.
          </p>
          <p>
            Caso ocorra a fusão da nossa loja com outra empresa, as suas
            informações podem ser transferidas para os novos proprietários para
            que possamos continuar a vender-lhe produtos.
          </p>
          <h2>PERGUNTAS E INFORMAÇÕES DE CONTATO</h2>
          <p>
            Se você gostaria de acessar, corrigir, alterar ou excluir quaisquer
            informações pessoais que temos sobre você, registar uma queixa, ou
            simplesmente pedir mais informações, contacte-nos através do e-mail:{" "}
            <a href="mailto:info@boomsy.pt">info@boomsy.pt</a>.
          </p>
          <p>[Oeiras, Lisboa, Portugal]</p>
        </div>
      </Copy>
    </Layout>
  )
}

export default PrivacyPolicyPage
